import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
const UnderConstructionPage = () => {
  return (
    <Layout>
      {" "}
      <SEO title="404: Not found" />{" "}
      <section
        className="uk-section uk-section-primary"
        data-uk-scrollspy="target: h1, h2, .height-xsmall, .phone-wrapper, .uk-card; cls: uk-animation-slide-bottom-medium; delay: 100"
      >
        {" "}
        <div className="uk-container">
          {" "}
          <div className="uk-position-relative uk-flex uk-flex-middle uk-flex-center">
            {" "}
            <div className="hero-outer-circle uk-visible@s"></div>{" "}
            <div className="hero-inner-circle uk-visible@s"></div>{" "}
          </div>{" "}
          <>
            {" "}
            <h1>Under construction</h1>{" "}
            <h2 style={{ textAlign: "center" }}>Please check back later.</h2>{" "}
          </>{" "}
        </div>{" "}
      </section>{" "}
    </Layout>
  )
}
export default UnderConstructionPage
